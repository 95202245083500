.no-content {
  padding: 15px 0;
  text-align: center;
}

.scroll-container {
  height: 95vh;
  // overflow-y: scroll;
  width: 100%;

  .load-more-btn {
    border-radius: 20px;
    margin: 10px 30px 10px 0;
  }
}

.notification-card {
  border-bottom: 1px solid $defaultThemeColor;
  cursor: pointer;
  margin: 3px;

  .notification-time {
    font-size: 12px;

    .date {
      text-align: left;
    }

    .ago {
      text-align: right;
    }

    .message {
      font-size: 13px;
      font-weight: bold;
      line-height: 1.5;
    }
  }
}
