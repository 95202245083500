.progress-loader {
  background-color: $loaderBgColor;
  height: 4px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  &::before {
    animation: loading 1s linear infinite;
    background-color: $buttonDefaultBgColor;
    content: '';
    display: block;
    height: 4px;
    left: -200px;
    position: absolute;
    width: 200px;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  to {
    left: 100%;
  }
}
