.search-and-export-data-panel {
  margin-left: 13px;

  input {
    &.grid-search-bar {
      border: 1px solid $contentBorderColor;
      border-radius: 5px;
      font-size: 15px;
      height: 33px;
      padding: 3px;
      padding-left: 10px;
      padding-right: 28px;
      width: 250px;

      &.top {
        margin-bottom: 15px;
      }

      &.bottom {
        margin-top: 15px;
      }
    }
  }

  label {
    &.input-icon {
      font-size: 15px;
      position: relative;
      right: 27px;
    }
  }

  .export-button {
    float: right;
    margin-right: 5px;

    &.top {
      margin-bottom: 15px;
    }

    &.bottom {
      margin-top: 15px;
    }

    .header-action-btn {
      background-color: $white;
      border: 1px solid $defaultThemeColor;
      border-radius: 5px;
      color: $defaultThemeColor;
      cursor: pointer;
      float: right;
      font-size: 14px;
      margin: 0 8px;
      padding: 7px 6px;
      vertical-align: middle;

      &:hover {
        border: 1px solid $defaultThemeColor;
        color: $defaultThemeColor;
      }
    }
  }
}

app-mi-grid-v2 {
  width: 100%;
  .col-12 {
    padding: 0;
  }
}
.max-limit-doc {
  color: #5d5c5c;
  font-size: 14px;
  width: 243%;
  display: inline-block;
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid #c8c8c8;
  background: #f4f4f4;
  color: #333333;
  padding: 1rem;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  height: 40px;
}

.mi-grid-component {
  background: $white;
  border-radius: 10px;
  height: 100%;
  margin: 0 auto;
  margin-top: 10px;
  overflow: hidden;
  padding: 20px 0;
  // width: 100%;

  .p-table {
    table {
      width: calc(100% + 2px);
    }

    .p-table-tbody {
      > tr {
        background: #fff;
        border-bottom: 1px solid $contentBorderColor;
        color: #333;

        &:focus {
          outline: 0;
        }

        &.p-state-highlight {
          background-color: $white;
          color: inherit;
        }

        &:not(.p-state-highlight) {
          &:hover {
            background-color: $white;
            color: inherit;
          }
        }

        &.even-row {
          background-color: #f4f5fa;

          // background-color: $evenRowColor;

          &:not(.p-state-highlight) {
            &:hover {
              background-color: $evenRowColor;
              color: inherit;
            }
          }
        }

        > td {
          border: 0;
          border-right: 1px solid #c0bebe;
          cursor: auto;
          font-size: 15px;
          padding: 10px 15px;

          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:last-child {
            border: 0;
          }

          .p-button {
            &.p-state-default {
              margin-right: 5px;

              &.update-status {
                background: transparent;
                border: 0;
                color: $defaultThemeColor;

                .p-button-text {
                  padding: 5px;
                }
              }
            }
          }
        }

        &:nth-child(even) {
          &.p-state-highlight {
            background: #f9f9f9;
            color: inherit;
          }
        }

        &:nth-child(odd) {
          &.p-state-highlight {
            background: #f4f5fa;
            color: inherit;
          }
        }
      }
    }

    .p-table-thead {
      > tr {
        > th {
          background: transparent;
          border-bottom: 1px solid #c0bebe;
          border-left: 0;
          border-right: 0;
          border-top: 0;
          clear: both;
          font-size: 15px;
          font-weight: 500;
          overflow: hidden;
          padding: 10px;
          text-align: left;
          white-space: unset;

          &:focus {
            outline: 0;
          }
        }
      }
    }

    .p-paginator-bottom {
      background-color: $white;
      border: 0;
      padding: 10px;

      a {
        border-radius: 50%;
        height: 2.5em;
        line-height: 2.5em;
        margin: 0 5px;
        width: 2.5em;
      }
    }

    .p-sortable-column {
      &.p-state-highlight {
        background-color: $white;
        color: inherit;

        .p-sortable-column-icon {
          color: inherit;
        }
      }

      &:not(.p-state-highlight) {
        &:hover {
          background-color: $white;
          color: inherit;

          .p-sortable-column-icon {
            color: inherit;
          }
        }
      }
    }
  }

  p-table {
    &.detail-screen-available {
      .p-table {
        .p-table-tbody {
          > {
            tr {
              > td {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .p-table-resizable {
    > .p-table-wrapper {
      overflow: inherit;
    }
  }
}

.grid-btn-div {
  color: #fff;
  cursor: pointer;
  line-height: 30px;
  text-align: center;

  &.pad-30-pc {
    padding: 30pc;
  }

  &.new-request-btn {
    background-color: #f00;
  }

  &.attended-btn {
    background-color: #808080;
  }

  &.closed-btn {
    background-color: #080;
  }
}

.grid-request-btn {
  &.new-request {
    color: #f00;
  }

  &.attended-request {
    color: #808080;
  }

  &.closed-request {
    color: #080;
  }
}

.action-header {
  text-align: center;
}

.grid-action {
  display: flex;
  justify-content: center;
}
