.time-setting-block {
  background: $white;
  padding: 10px;
}

.time-setting-spinner {
  .p-spinner {
    width: 50px;

    input {
      padding-left: 5px;
    }
  }
}

.time-setting-group-name {
  font-weight: bold;
}

.about-company-location-map {
  height: 300px;
}

.about-company-fileuploader {
  .p-widget-header {
    background: transparent;
    border-color: transparent;
    margin-top: 50%;
  }
}

.about-company-form {
  .form-label {
    margin-top: 16px;
  }
}

.custom-dropdown-wrapper {
  border: 1px solid $contentBorderColor;
  margin-top: 7px;
  position: relative;
}

.selected-vehicle-icon {
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-top: 8px;
  width: 200px;
}

.vehicle-icon-list-wrapper {
  background-color: $white;
  border: 1px solid $contentBorderColor;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .3);
  position: absolute;
  width: 214px;
  z-index: 999;
}

.car-icon-dropdown-caret {
  color: $taxipulseThemeColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.vehicle-icon-list {
  max-height: 200px;
  overflow-y: auto;
}
