body {
  background-color: $bodyBgColor;
  color: $BodyTextColor;
  font-family: $fontFamily;
  font-size: $fontSize;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  .p-widget {
    font-family: $fontFamily;
  }
}

.sign-in-icon {
  height: 20px;
  margin: 15px 0 0 20px;
  width: 25px;
}

.white-background {
  background-color: transparent;
}

.common-border-radius {
  border-radius: 5px;
}

.text-center {
  text-align: center;
}

.mrg-top-10 {
  margin-top: 10px;
}

.image-code {
  background: url("/assets/images/temp/sprite.png") 0 0;
}

.image-rating-width {
  height: 25px;
  width: 25px;
}

.p-rating {
  a {
    .p-rating-icon {
      color: $defaultThemeColor;
    }
  }
}

.rating1 {
  background-position: -69px -38px;
}

.rating2 {
  background-position: -119px -38px;
}

.rating3 {
  background-position: -168px -38px;
}

.rating4 {
  background-position: -219px -38px;
}

.rating5 {
  background-position: -269px -38px;
}

.track-driver {
  cursor: pointer;
  float: right;

  &:hover {
    color: $defaultThemeColor;
  }
}

input {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

a {
  color: $linkColor;

  &:link,
  &:active,
  &:focus {
    color: $linkColor;
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: $stateFocusBgColor;
    outline: none;
    text-decoration: none;
  }
}

.main-panel {
  background-color: $mainContentBgColor;
  display: flex;
  margin-top: 62px;
}

.page-header {
  background-color: $pageHeaderBgColor;
}

.page-buttons {
  button {
    float: right;
    margin: 0 0 0 8px;
  }
}

.ql-editor {
  background: $white;
}

p-tabmenu {
  .p-widget-content {
    border: 0 none;
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    .p-tabmenuitem {
      .p-menuitem-link {
        border-width: 1px;
        border-color: #c8c8c8;
        padding: 0.857rem 1rem;
        transition: background-color 0.2s, box-shadow 0.2s;
        margin: -4px 0px -4px 0px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border: 0;
        background: transparent;
        color: #fff;
        font-weight: 500;
      }
    }
  }
}

ul {
  &.p-widget-header {
    border: 0 none;

    .p-tabmenuitem {
      a {
        color: $inputTextColor;
      }

      &.p-state-active {
        a {
          color: $gridHeaderTextColor;
        }
      }
    }
  }
}

.p-tabmenu-nav {
  background: $stateDefaultBgColor;
  border: 0;
  font-size: 13px;

  li {
    &.p-tabmenuitem {
      background-color: $stateDefaultBgColor;

      &:not(.p-state-active) {
        background-color: $sideBarHoverBgColor;

        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $stateDefaultBorderColor;

          a {
            color: #fff;
          }
        }

        a {
          color: #fff;
        }
      }

      .p-menuitem-link {
        &.p-corner-all {
          .p-menuitem-text {
            color: $white;
          }

          .p-menuitem-icon {
            color: $white;
          }
        }
      }
    }
  }
}

.panel-div {
  background-color: $panelDivBgColor;
  padding: 8px;
}

.panel-div-top-info {
  padding: 0 0 8px;

  .left-info {
    text-align: left;
  }

  .right-info {
    text-align: right;
  }
}

h1 {
  &.page-title {
    font-size: 18px;
  }
}

.bread-crumbs {
  float: right;
  padding: 14px 5px;
}

.bread-item {
  display: inline-block;
  list-style-type: none;
}

.p-panel {
  .p-widget-header {
    font-size: $headerFontSize;
    font-weight: bold;
  }

  .p-panel-titlebar {
    padding: 8px 16px;
  }

  .p-panel-content {
    background: none;
    border: 0;
    padding: 8px 16px;
  }
}

.referral-form-label {
  padding-top: 20px;
}

.p-button {
  &.p-state-default {
    background: $taxipulseThemeColor;
    border: 1px solid $taxipulseThemeColor;
    color: $buttonDefaultTextColor;

    &:hover {
      background: $themeButtonHoverColor;
      border: 1px solid $themeButtonHoverColor;
    }

    &.margin-button {
      margin-right: 16px;
    }
  }

  &.negative-button {
    background-color: $negativeButtonColor;
    border: 1px solid $negativeButtonColor;

    &:hover {
      background-color: $negativeButtonHoverColor;
      border: 1px solid $negativeButtonHoverColor;
    }
  }

  &.p-button-danger {
    &:enabled {
      &:hover {
        background-color: $stateFocusBgColor;
      }
    }

    &:focus {
      background-color: $stateFocusBgColor;
    }
  }
}

.p-button-text-only {
  .p-button-text {
    padding: 8px 16px;
  }
}

.p-button-text-icon-left {
  font-size: 18px;

  .p-button-text {
    font-size: 13px;
    padding: 8px 10px 8px 36px;
  }
}

.p-inputtext {
  &.p-state-default {
    background: $inputFieldsBgColor;
    border: 1px solid $inputFieldBorderColor;
    color: $contentTextColor;
    padding: 12px 16px;
  }

  &:enabled {
    &:hover {
      border: 1px solid $inputFieldBorderColor;
      box-shadow: none;
    }

    &:focus {
      border: 1px solid $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.p-growl-item-container {
  &.p-state-highlight {
    &.p-growl-message-success {
      background-color: $growlMessageSuccessColor;
      border-color: $growlMessageSuccessColor;
    }

    &.p-growl-message-error {
      background-color: $growlMessageErrorColor;
      border-color: $growlMessageErrorColor;
    }
  }
}

.partner-box {
  background: $white;
  border: 1px solid $inputFieldBorderColor;
  color: $headerTextColor;
  margin-bottom: 20px;
}

.out-partner-info {
  cursor: pointer;
  text-align: right;
}

.lang-right-to-left {
  .form-label,
  a,
  .p-panel-titlebar,
  p {
    direction: rtl;
  }

  input {
    &[type="text"] {
      direction: rtl;
    }
  }

  .p-panelmenu {
    .p-menuitem-icon {
      &.fa {
        margin-left: 0.5em;
      }
    }
  }
}

.assign {
  .p-inputtext {
    &.p-state-default {
      height: 32px;
      padding: 0 10px;
      width: 100%;
    }
  }

  .p-autocomplete {
    width: 100%;

    .p-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 9px;
      vertical-align: top;
      width: 22px;
    }
  }

  &.search-driver {
    .p-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 1px;
      vertical-align: top;
      width: 22px;
    }
  }
}

.web-link {
  color: $stateDefaultTextColor;
}

.caps {
  text-transform: uppercase;
}

.map {
  height: 100%;
  width: 100%;
}

.closed {
  .sidebar {
    width: 60px;

    .company-logo {
      padding: 31px;

      img {
        display: none;
      }
    }

    .p-menuitem-text {
      display: none;
    }
  }

  .header-panel {
    .company-logo {
      width: 60px;

      img {
        display: none;
      }
    }

    .p-menuitem-text {
      display: inline-block;
    }
  }

  .main-panel {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

.mi-pie-chart {
  display: inline-block;
  height: auto;
  width: 250px;
}

.mi-bar-chart {
  display: inline-block;
  height: 271px;
  width: 60%;
}

.flexbox {
  flex: 1;
  height: 233px;
}

.error {
  color: $defaultThemeColor;
  font-size: 16px;
}

.details {
  display: flex;
}

.top-padding {
  padding-top: 3%;
}

.document {
  cursor: pointer;
}

.loader-hidden {
  visibility: hidden;
}

.loader-page-center-overlay {
  background-color: $loaderBgColor;
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 88px;
  z-index: 500000;
}

.info-label {
  color: #3d4246;
  display: inline-block;
  font-weight: bold;
}

.info-data {
  display: inline-block;
}

.device-height {
  min-height: 100vh;
}

.add-edit-panel {
  padding: 30px 20px 20px;
}

.p-button-icon-only {
  .p-button-text {
    margin-top: -2px;
  }
}

.p-autocomplete {
  .p-autocomplete-dropdown {
    height: 30px;
    position: absolute;
    right: 0;
    top: 7px;
    vertical-align: inherit;
  }
}

.user-pic {
  margin: 5% 15%;
  width: 70%;
}

p-calendar {
  .p-calendar {
    &.p-calendar-w-btn {
      top: 8px;
      height: 30px;
      input {
        &[type="text"] {
          &:hover {
            border-color: $inputBorderColor;
          }

          &:focus {
            border-color: $inputBorderColor;
          }
        }
      }
    }
  }

  &.short-input {
    .p-calendar {
      input {
        &[type="text"] {
          width: 95%;
        }
      }
    }

    .p-button {
      &.p-state-default {
        right: 4%;
      }
    }
  }

  .p-button {
    &.p-state-default {
      background-color: $inputFieldsBgColor;
      border: 1px solid $inputFieldBorderColor;
      border-left: 0 none;
      color: $calendarIconColor;
      height: 30px;
      margin: 8px 1px;
      position: absolute;
      right: 0;
    }
  }

  input {
    width: 100%;
  }
}

.p-spinner {
  .p-spinner-up {
    font-size: 12px;
    height: 14px;
    margin: 8px 0 0;
  }

  .p-spinner-down {
    font-size: 12px;
    height: 14px;
    margin: 0;
    top: 24px;
  }
}

.inner-container {
  padding: 0 15px;
}

.gridparent {
  margin: 0 6px;
  padding: 0;
}

.img-doc {
  max-width: 60px;
}

.active-box {
  background: $white;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.33);
  color: $activeBoxTextColor;
  margin-right: 20px;
  // padding: 7px 15px;
  text-align: left;

  .special-count {
    float: right;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.form-buttons {
  &.corporate-button-bar {
    margin-top: 60px;
  }

  button {
    &.destination-add-full-btn {
      margin: 0;
      width: 100%;
    }
  }
}

.reset-btn {
  background-color: $white;
  border: 1px solid $taxipulseThemeColor;
  border-radius: 4px;
  color: $taxipulseThemeColor;
  cursor: pointer;
  float: left;
  margin-top: 7px;
  padding: 6px 10px;
}

p-dropdown {
  .p-dropdown-items {
    .p-state-highlight {
      background: $dropdownBgColor;
      border-color: $dropdownBorderColor;
      color: $white;
    }
  }

  .p-dropdown {
    border-color: $inputFieldBorderColor;

    &:not(.p-state-disabled) {
      &:active {
        border-color: $inputFieldBorderColor;
      }

      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .p-dropdown-trigger {
      .fa {
        color: $taxipulseThemeColor;
      }
    }
  }

  .p-inputtext {
    &:focus {
      border-color: $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.form-table-label {
  background: $gridHeaderBgColor;
  margin-bottom: 5px;
  padding: 5px;
}

.form-table-value {
  margin-bottom: 5px;
  padding: 5px;
}

.p-chkbox-box,
.p-radiobutton-box {
  &.p-state-default {
    background: $checkboxBackgroundColor;
    border-color: $checkboxActiveBorderColor;
    color: $checkboxCheckColor;
  }

  &:not(.p-state-disabled) {
    &:not(.p-state-active) {
      &:hover {
        background-color: $checkboxBackgroundColor;
        border-color: $checkboxActiveBorderColor;
      }
    }
  }

  &.p-state-active {
    border-color: $checkboxActiveBorderColor;
  }
}

.form-field {
  .asterisk {
    color: $growlMessageErrorColor;
  }

  .employee-input {
    input {
      &[type="text"] {
        width: 100%;
      }
    }
  }

  .p-dropdown-panel {
    input {
      &[type="text"] {
        width: 100%;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-dropdown-filter-container {
      width: 100%;

      .fa {
        position: absolute;
        right: 21px;
        top: 21px;
      }
    }

    .p-dropdown-item {
      &:not(.p-state-highlight) {
        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $secondaryThemeColor;
        }
      }
    }
  }
}

.delete-bin {
  &.fa {
    cursor: pointer;
    float: right;
    font-size: 16px;

    &:hover {
      color: $defaultThemeColor;
    }
  }
}

.document-container {
  width: 100%;
}

.p-datepicker {
  .p-datepicker-calendar {
    td {
      &:not(.p-state-disabled) {
        a {
          &.p-state-highlight {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
            color: $white;
          }

          &.p-state-active {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
            color: $white;
          }

          &:hover {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
          }
        }
      }
    }
  }
}

.scroll-panel {
  overflow: scroll;
  padding: 0 0 16px;
}

.box-bottom-border {
  border-bottom: 1px solid $sidebarIconColor;
}

.mt-10 {
  margin-top: 10px;
}

.step-tab {
  .p-steps-item {
    &.p-state-highlight {
      background-color: $secondaryThemeColor;

      a {
        color: $white;

        &:focus {
          color: $white;
        }
      }

      &:hover {
        a {
          color: $white;
        }
      }
    }

    &.p-state-disabled {
      a {
        color: $secondaryThemeColor;
      }
    }
  }
}

p-multiselect {
  .p-multiselect {
    border-color: $inputFieldBorderColor;
    height: 32px;
    margin: 9px 0;

    &:not(.p-state-disabled) {
      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .p-multiselect-trigger {
      border: 0 none;
      color: $defaultThemeColor;
    }

    .p-multiselect-label {
      margin: 0;
    }
  }
}

p-selectbutton {
  .p-selectbutton {
    .p-button {
      &:not(.p-state-disabled) {
        &:not(.p-state-active) {
          &:hover {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
          }
        }
      }
    }
  }
}

p-chips {
  .p-chips {
    .p-inputtext {
      &:hover {
        border-color: $inputFieldBorderColor;
      }

      .p-chips-token {
        &.p-state-highlight {
          background-color: $defaultThemeColor;
          border-color: $defaultThemeColor;
        }
      }

      .p-chips-input-token {
        input {
          &[type="text"] {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.right-align {
  padding-right: 3%;
  text-align: right;
}

.same-height-div {
  display: flex;

  .beside-div-same-height {
    background: $white;
    flex: 1;
    margin: 0 0.5em 0.5em;
    padding: 0;
  }
}

p-dialog {
  .p-dialog {
    &.p-corner-all {
      border: 0;
    }

    .p-dialog-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $white;
      font-size: 16px;
      padding: 1em 1.1em;
      position: relative;

      .p-dialog-titlebar-close {
        border: 0;
        color: $white;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .p-dialog-footer {
      overflow: hidden;
      padding: 0.8em;
    }
  }
}

.add-vehicle {
  .p-dialog {
    &.p-corner-all {
      border: 0;
    }

    .p-dialog-titlebar {
      background: $white;
      border: 0;
      color: #464444;
      font-size: 18px;
      font-weight: 400px;
      padding: 1em 1.1em;
      position: relative;
      text-align: center;

      .p-dialog-titlebar-close {
        border: 0;
        color: #3b7bfd;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .p-dialog-content {
      background-color: $white;
      border: 1px solid #c8c8c8;
      color: #333;
      min-height: 240px;
      padding: 0.571em 1em;
    }

    .vehicle-list-wrapper {
      max-height: 243px;
    }

    .vehicle-list-row {
      border: 1px solid #ddd;
      border-radius: 5px;
      display: inline-block;
      float: left;
      margin-left: 10px;
      margin-top: 4px;
      padding: 5px;
    }

    .p-dialog-footer {
      overflow: hidden;
      padding: 0.8em;
    }

    .vehicle-text-color {
      color: #3b7bfd;
      font-size: 12px;
    }

    .vehicle-footer {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.remove-vehicle {
  .p-dialog {
    &.p-corner-all {
      border: 0;
    }

    .p-dialog-titlebar {
      background: $white;
      border: 0;
      color: #464444;
      font-size: 18px;
      font-weight: 400px;
      padding: 1em 1.1em;
      position: relative;
      text-align: center;

      .p-dialog-titlebar-close {
        border: 0;
        color: #3b7bfd;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .p-dialog-content {
      background-color: $white;
      border: 1px solid #c8c8c8;
      color: #333;
      height: 250px;
      padding: 0;
    }

    .p-dialog-footer {
      overflow: hidden;
      padding: 0.8em;
    }

    .remove-vehicle-parent {
      &:nth-child(odd) {
        background-color: #efefef;
      }
    }
  }
}

.access-checkbox-div {
  display: inline-block;
  padding: 5px;
}

.access-editbtn-div {
  display: inline-block;
  padding: 5px;
}

.department-autocomplete {
  margin-top: 4%;

  .p-inputtext {
    input {
      &[type="text"] {
        border: 0 none;
      }
    }
  }

  .p-state-default {
    padding: 10px;
  }

  .p-autocomplete-panel {
    width: 96%;
  }

  ul {
    width: 96%;

    li {
      font-size: 14px;
    }
  }
}

.faq-form {
  margin-bottom: 30px;
}

.faq-panel-marin {
  margin-bottom: 2%;

  .content {
    padding-bottom: 2%;
    text-align: justify;
  }

  .footer {
    text-align: right;
  }

  p-header {
    font-size: 14px;
    padding: 0;
  }

  &.p-panel {
    padding: 0;

    .p-panel-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $sidebarIconColor;
    }

    .p-panel-titlebar-icon {
      color: $secondaryThemeColor;
      font-size: 13px;
      margin-top: 5px;

      span {
        margin-top: 3px;
      }
    }

    .p-panel-content {
      padding: 16px;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
}

.term-conditions {
  padding: 45px;

  h3 {
    color: $sideBarHoverBgColor;
    font-size: 17px;
    margin: 0;
  }
}

.ql-align-justify {
  color: $secondaryThemeColor;
  font-size: 15px;
  margin: 10px 0 45px;
  text-align: justify;
}

.driverassigndropdown {
  .p-autocomplete {
    width: 100%;

    input {
      &[type="text"] {
        border: 1px solid #e1e3e7;
        font-family: "OpenSans", sans-serif;
        font-size: 14px;
        height: 32px;
        margin: 7px 0;
        padding: 0 2%;
        width: 94%;
      }
    }

    .p-autocomplete-dropdown {
      border: 0 none;
      position: absolute;
      right: 2px;
      top: 8px;
      vertical-align: top;
    }
  }

  .driver-name {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: left;
    width: 23%;
  }

  .driver-ref-no {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: center;
    width: 35%;
  }

  .driver-eta {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: right;
    width: 26%;
  }
}

.trip-details {
  .pickup-circle {
    color: $mapPickup;
  }

  .drop-circle {
    color: $mapDrop;
  }

  .one-line-block {
    float: right;
    margin-left: 20px;
  }

  .p-fileupload-buttonbar {
    padding: 0 0 0 10px;
  }
}

.assign-btn {
  float: right;
  margin: 0 15px 9px 0;
}

.attached-docs {
  fieldset {
    margin: 20px 0;
  }

  ul {
    margin: 0;
    padding: 0;

    .panel-div {
      padding: 0 15px;
    }
  }
}

.destination-box {
  background: $destinationBox;
  border-bottom: 1px solid $secondaryThemeColor;
  overflow: auto;
}

.forgot-password-link {
  cursor: pointer;
}

.rental {
  .destination-box {
    background: $destinationBox;
    border-bottom: 1px solid $secondaryThemeColor;
    display: block;
    min-height: 186px;
    overflow: auto;
  }
}

.pricing-bundle {
  min-height: 196px;
}

.fa-trash {
  cursor: pointer;
}

.fa-pencil {
  cursor: pointer;
  padding-left: 5px;
}

.position-relative {
  position: relative;
}

.at-bottom {
  bottom: 0;
  position: absolute;
}

.assign-search-input-layout {
  margin: 15px 0 10px;
}

.type-of-passenger {
  margin-top: 10px;
}

.driver-eta {
  float: right;
}

.m-20 {
  margin: 20px;
}

.emergency-note {
  color: $defaultThemeColor;
}

.route-map-height {
  height: 446px;
}

.disabled-row {
  background: $stateDefaultBorderColor;
}

.enabled-row {
  background: $white;
}

.page-header-text {
  min-height: 45px;
  position: relative;

  & div {
    bottom: 0;
    font-weight: bold;
    height: 30%;
    margin: auto;
    position: absolute;
    top: 0;
  }
}

.custom-grid {
  text-align: left;

  .grid-header {
    background: #ededed;
    border-radius: 3px;
    color: #1e1e1e;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: 0;
    opacity: 0.7;
    padding: 10px;
  }

  .text-center {
    margin: auto 0;
  }

  .custom-col-16 {
    margin: auto;
    width: 16%;
  }

  .custom-col-12 {
    margin: auto;
    width: 12%;
  }
}

.platform-summery-container {
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 4% 2%;
  text-align: center;
  width: 96%;

  .summery-icon {
    padding-bottom: 4%;
    width: 100%;
  }

  .summery-count {
    font-size: 25px;
    font-weight: bold;
    width: 100%;
  }

  .summery-title {
    font-size: 15px;
    font-weight: bold;
    padding-top: 4%;
  }
}

.form-border {
  border: 1px solid $inputFieldBorderColor;
  border-radius: 4px;
  padding: 5px;

  &.side-popup {
    height: 330px;

    .list-data {
      height: 97%;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
  &.side-popup2 {
    margin-top: 25px;
    height: 150px;

    .list-data {
      height: 97%;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

p-datatable {
  table {
    tbody {
      tr {
        &.p-rowgroup-header {
          background-color: #e4e4e4;
          border: 1px solid #ccc;
          height: 40px;
        }

        &.p-widget-content {
          height: 30px;
        }
      }
    }

    thead {
      th {
        color: #444;
        font-weight: bold;
        height: 40px;
      }
    }
  }
}

.user-management-role {
  p-table {
    table {
      tbody {
        tr {
          &.p-rowgroup-header {
            background-color: $pDatatableBgColor;
            border: 1px solid $pDatatableBorderColor;
            height: 40px;
          }

          &.p-widget-content {
            height: 30px;
          }
        }
      }

      thead {
        th {
          color: $headerTextColor;
          font-weight: bold;
          height: 40px;
        }
      }
    }
  }
}

.track-my-location-error {
  font-size: 16px;
  left: 30%;
  position: absolute;
  text-align: center;
  top: 50%;
}

.track-my-location-container {
  background-size: cover;
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .track-my-location {
    .header {
      .contact-info {
        margin-top: 10px;
      }
    }

    .seperator {
      background-color: #ccc;
      height: 1px;
      width: 100%;
    }

    .driver-logo {
      border: 1px solid #808080;
      border-radius: 50%;
      height: 150px;
      width: 150px;
    }

    .content-header {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: left;
    }

    .map-container {
      height: 400px;
    }

    .content {
      margin-bottom: 10px;

      .content-info {
        font-size: 13px;
        text-align: left;

        .data-info {
          color: #a2a2a2;
        }

        div {
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 15px;
          word-wrap: break-word;

          .start {
            color: #008000;
            margin-right: 2px;
          }

          .end {
            color: #f00;
            margin-right: 2px;
          }
        }

        .star {
          i {
            display: inline-block;
            margin: 0 3px;
            text-align: center;
          }

          .active {
            color: $defaultThemeColor;
          }
        }

        table {
          background-color: transparent;
          border-collapse: collapse;
          border-spacing: 0;
          padding: 0 10px;
          width: 100%;

          tbody {
            border-color: inherit;
            display: table-row-group;
            vertical-align: middle;

            tr {
              height: 29px;

              .value {
                color: #a2a2a2;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.booking-details {
  .booking-details-header {
    .trip-id {
      font-size: 16px;
      font-weight: bold;
      margin: 15px 10px;
    }

    .trip-time {
      font-size: 14px;
      margin: 15px 10px;

      i {
        margin: 0 5px;
      }
    }

    .amount {
      font-size: 31px;
      font-weight: bold;
      padding: 43px 20px;
      text-align: center;
    }

    .trip-status {
      margin: 15px 10px;

      .key {
        color: #333;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }

      .value {
        color: #00b911;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .address-container {
    .point-top-pad {
      padding-top: 10px;
      width: 30px;
    }

    .start-point {
      background-color: #00b911;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }

    .divider {
      background-color: #ccc;
      height: 103px;
      margin-left: 3px;
      margin-top: 5px;
      width: 3px;
    }

    .end-point {
      background-color: #f00;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }

    p {
      margin: 0;
      min-height: 25px;
    }

    .time {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 10px;
    }
  }

  .booking-detail-container {
    .header {
      border-left: 5px solid #3b7bff;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 30px;
    }

    table {
      padding: 0 25px;
      width: 100%;

      tr {
        height: 35px;

        td {
          &.key {
            color: #9b9b9b;
            width: 20%;
          }

          &.statistic-key {
            color: #9b9b9b;
            width: 50%;
          }

          &.statistic-key-highlight {
            color: #000;
            font-weight: bold;
          }
        }
      }
    }

    .rating {
      margin-top: 10px;
      padding: 0 27px;

      .rating-header {
        color: #9b9b9b;
        margin-bottom: 30px;
      }

      .rating-container {
        margin-left: -21px;

        .rating-stars {
          background-color: #fff;
          border: 1px solid #9b9b9b;
          float: left;
          margin-left: 21px;
          margin-top: -15px;
          padding: 6px;
          text-align: center;
          width: 115px;

          i {
            color: #9b9b9b;
            padding: 0 2px;

            &.active {
              color: $defaultThemeColor;
            }
          }
        }

        .rating-comments {
          border: 1px solid #9b9b9b;
          padding: 5%;
          width: 95%;
        }
      }
    }

    .other-passenger-details {
      margin-top: 10px;
      padding: 0 27px;

      .other-passenger-details-header {
        color: #9b9b9b;
        // margin-bottom: 30px;
      }
    }
  }

  .grid-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      &.source-location {
        &::before {
          color: #008000;
          content: "•";
          display: inline-block;
          font-size: 18px;
          margin-left: -12px;
          width: 12px;
        }
      }

      &.destination-location {
        &::before {
          color: #f00;
          content: "•";
          display: inline-block;
          font-size: 18px;
          margin-left: -12px;
          width: 12px;
        }
      }
    }
  }

  // .cartype-icon {
  //   .p-dropdown-items {
  //     .p-state-highlight {
  //       background-color: $carTypeIconHighlight;
  //     }
  //   }
  // }

  .cartype-icon {
    .p-dropdown-panel {
      .p-dropdown-item {
        &:not(.p-state-highlight) {
          &:hover {
            background-color: $carTypeDropdownHover;
          }
        }
      }
    }

    .p-dropdown-items {
      .p-state-highlight {
        background-color: $carTypeIconHighlight;
      }
    }
  }

  .car-icon-0 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -847px -418px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-1 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -60px -57px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-2 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -250px -57px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-3 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -445px -57px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-4 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -648px -57px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-5 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -852px -57px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-6 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -60px -148px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-7 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -250px -148px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-8 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -445px -148px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-9 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -648px -148px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-10 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -852px -148px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-11 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -60px -233px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-12 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -250px -233px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-13 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -445px -233px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-14 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -648px -233px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-15 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -852px -233px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-16 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -60px -327px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-17 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -250px -327px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-18 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -445px -327px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-19 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -648px -327px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-20 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -852px -327px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-21 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -60px -418px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-22 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -250px -418px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-23 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -445px -418px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  .car-icon-24 {
    background-image: url("/assets/images/temp/car_type_icons-2.png");
    background-position: -648px -418px;
    background-repeat: no-repeat;
    height: 35px;
    width: 180px;
    zoom: 1;
  }

  p-panelmenu {
    p-panelmenusub {
      ul {
        li {
          background-color: #f6f6f6;

          a {
            padding: 12px 16px 10px;

            span {
              &.p-menuitem-icon {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  p-sidebar {
    .p-sidebar {
      padding: 0;
    }

    .p-sidebar-close {
      color: #fff;
      margin: 10px;
    }

    .notification-container {
      height: 100vh;

      .header {
        background-color: $defaultThemeColor;
        color: #fff;
        padding: 10px;
        text-align: center;
      }
    }
  }

  .free-car-icon {
    background: url("/assets/images/temp/taxi_free.png");
    height: 36px;
    width: 30px;
  }

  .busy-car-icon {
    background: url("/assets/images/temp/taxi_busy.png");
    height: 36px;
    width: 30px;
  }

  .idle-car-icon {
    background: url("/assets/images/temp/taxi_idle.png");
    height: 36px;
    width: 30px;
  }

  .google-map-info-marker {
    font-family: "OpenSans", sans-serif;
    font-size: 12px;
    font-weight: bold;

    .row-item {
      margin: 5px;

      .key {
        color: #000;
      }

      .value {
        color: $defaultThemeColor;
      }
    }
  }

  .single-line-form-table {
    width: 100%;

    .form-title {
      width: 20%;
    }

    .form-control {
      width: 30%;

      .p-dropdown {
        .p-dropdown-label {
          padding: 3px 0 0 5px;
        }
      }
    }

    .form-errors {
      text-align: end;
      width: 50%;
    }
  }

  .emergency-map-container {
    box-shadow: 1px 1px 1px #aaa;
    height: 400px;
    margin-top: 10px;
  }

  .emergency-map-container1 {
    box-shadow: 1px 1px 1px #aaa;
    height: 300px;
    margin-top: 10px;
  }

  .arrange-vehicle-container {
    p-pickList {
      .p-widget-header {
        background: transparent;
        border: 0;
      }

      .p-picklist-responsive {
        .p-picklist-buttons {
          button {
            margin: 10px auto;
            width: 50%;
          }
        }

        .p-picklist-listwrapper {
          .p-picklist-list {
            height: 33em;
            padding: 5px;

            li {
              border-bottom: 1px solid $defaultThemeColor;

              &:hover {
                background: #aaa;
                border-bottom: 1px solid $defaultThemeColor;
              }

              &.p-state-highlight {
                background: #aaa;
              }
            }
          }
        }
      }
    }
  }

  .ride-share-details {
    margin-bottom: 20px;

    tr {
      height: 25px;
    }
  }

  .ride-share-passenger-list-container {
    table {
      border-collapse: collapse;
      color: #1e1e1e;
      text-align: center;

      tr {
        height: 35px;

        th {
          background: #ededed;
          border: 1px solid #aaa;
        }

        td {
          border: 1px solid #aaa;
        }
      }
    }
  }

  .bidding-detail-container {
    border-collapse: collapse;
    color: #1e1e1e;
    text-align: center;

    tr {
      height: 40px;

      &.odd {
        background-color: #f2f2f2;
      }

      th {
        border-top: 1px solid #aaa;
        height: 50px;
      }
    }
  }

  .booking-fares-container {
    table {
      border-collapse: collapse;
      color: #1e1e1e;
      margin-top: 4px;
      text-align: center;

      tr {
        height: 35px;

        th {
          background: #ededed;
          border: 1px solid #aaa;
        }

        td {
          border: 1px solid #aaa;
        }
      }
    }
  }

  .p-tabview {
    &.p-tabview-top {
      background: #f6f6f6;
      border: 0;
      padding: 0;

      .p-tabview-nav {
        li {
          &.p-state-active {
            background: #fff;
          }

          &:not(.p-state-active) {
            &:not(.p-state-disabled) {
              &:hover {
                background: #ccc;
                border: 0;
                color: #fff;
              }
            }
          }

          &:focus {
            outline: 0;
          }
        }

        .p-state-default {
          background: #a0a0a0;
        }

        a {
          color: #000;
        }
      }
    }
  }

  .driver-inline-flex {
    display: flex;
    flex-wrap: wrap;
  }

  p-toast {
    .p-growl {
      z-index: 9000;
    }
  }

  .p-multiselect-header {
    .p-multiselect-filter-container {
      .p-multiselect-filter-icon {
        left: 0.28em;
        position: absolute;
        top: 1.1em;
      }
    }
  }

  // Stripe Card CSS
  form {
    &.checkout {
      background: $white;
      border: 2px solid #eee;
      border-radius: 8px;
      color: #525252;
      font-family: monospace;
      font-size: 1.1rem;
      margin: 2rem auto;
      max-width: 500px;
      padding: 1rem 2rem;
      text-align: center;

      button {
        background: #ff7f50;
        border: 0;
        border-radius: 4px;
        color: $white;
        margin-top: 1rem;
        padding: 0.5rem 1rem;

        &:active {
          background: rgb(165, 76, 43);
        }
      }
    }
  }

  .stripe-element {
    background-color: $white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    margin: 1rem 0;
    padding: 8px 12px;
    transition: box-shadow 150ms ease;
  }

  .stripe-element--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .stripe-element--invalid {
    border-color: #fa755a;
  }

  .stripe-element--autofill {
    background-color: #fefde5;
  }

  .pi-spinner {
    &::before {
      content: none;
    }
  }

  .email-loader {
    height: 16px;
    position: absolute;
    right: 15px;
    top: 37px;
    width: 16px;
  }

  // .p-widget-overlay {
  //   z-index: 100 !important;
  // }

  .multiple-checked {
    height: 30px;
    margin-top: -16px;
    right: 124px;
  }

  .backgroud-patch {
    background: $white;
    border-radius: 6px;
    padding: 19px;
  }

  .max-limit {
    color: #5d5c5c;
    font-size: 13px;
  }

  .max-limit-doc {
    color: #5d5c5c;
    font-size: 10px;
  }

  .drop-box {
    border: 1px solid #ded7d7;
  }

  .service-time {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .pricing-block {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;

    .calender-block {
      margin: 0;
      width: 25px;
    }

    .unique-block {
      p-checkbox {
        .p-widget {
          font-size: 24px;
          height: 30px;
          margin: 5px 0;
          width: 30px;
        }

        .p-chkbox-icon {
          padding: 2px;
        }
      }
    }
  }

  .action-buttons {
    line-height: 3.5em;
    padding-left: 30px;

    .add-blocks {
      background: $defaultThemeColor;
      border-radius: 50%;
      color: $white;
      margin-right: 5px;
      padding: 5px 8px;
    }

    .remove-blocks {
      background: $white;
      border: 1px solid $headerTextColor;
      border-radius: 50%;
      color: $headerTextColor;
      margin-right: 5px;
      padding: 5px 8px;
    }
  }

  .action-buttons1 {
    line-height: 3.5em;
    padding-left: 30px;

    .add-blocks {
      background: $defaultThemeColor;
      border-radius: 50%;
      color: $white;
      margin-right: 5px;
      padding: 5px 8px;
    }

    .remove-blocks {
      background: $white;
      border: 1px solid $headerTextColor;
      border-radius: 50%;
      color: $headerTextColor;
      margin-right: 5px;
      padding: 5px 8px;
    }
  }

  .spinner-adjust {
    margin-top: 2px;
  }

  .submit-buttons {
    text-align: right;

    button {
      margin-right: 13px;
    }
  }

  .calender-width {
    padding-right: 40px;
  }

  .surge-block-border {
    border: 1px solid #dedede;
    margin-bottom: 15px;
  }

  .p-galleria {
    .p-galleria-panel {
      text-align: center;
    }
  }

  .display-image {
    .p-fileupload {
      .p-fileupload {
        .p-fileupload-buttonbar {
          background: #f0f2f7;
        }
      }
    }
  }

  .sPoint {
    background-color: #00b911;
    border-radius: 50%;
    height: 12px;
    width: 12px;
  }

  .ePoint {
    background-color: red;
    border-radius: 50%;
    height: 12px;
    width: 12px;
  }

  .rightSpace {
    padding-right: 9px;
  }

  .sEnv {
    padding: 27px;
    max-width: 4px;
  }

  .parameter {
    border: 1px solid #e8dede;
    text-align: center;
  }

  .mr-top-20 {
    margin-top: 20px;
  }

  .hand-pointer {
    cursor: pointer;
  }

  .subscription-active {
    color: #008000;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }

  //End Stripe Card
  .status {
    &.activate {
      color: $activeStatus;
    }

    &.deactivate {
      color: $deactiveStatus;
    }
  }

  // body .p-paginator .p-paginator-pages .p-paginator-page.p-state-active {
  //   background-color: #9947fc;
  //   color: #$white;
  // }

  // body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-state-active {
  //   background-color: #9947fc;
  //   border: 1px solid #9947fc;
  // }

  // body .p-button:enabled:hover {
  //   background-color: #b990ea;
  //   color: #$white;
  //   border-color: #b990ea;
  // }

  // body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active, body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active, body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active {
  //   background-color: #9947fc;
  //   border: 1px solid #9947fc;
  // }

  // body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-state-highlight, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-state-highlight {
  //   color: #$white;
  //   background-color: #9947fc;
  // }

  .collapse-header-multiple-checked {
    margin-left: 40%;
  }

  .wrap-text {
    word-break: break-word;
  }

  .extend-width {
    width: 24rem;
  }

  .region-priority {
    .p-orderlist-list {
      min-height: 450px;
    }
  }

  .car-add {
    color: #000;
  }

  .car-remove {
    color: #f00;
  }

  .right-panel {
    height: 77px;
  }

  .map-height {
    height: 250px;
    width: 100%;
  }

  .p-tabview-title {
    font-weight: 500;
  }

  .box-width {
    width: 35rem;
  }

  .p-table {
    .p-table-tbody > tr {
      background-color: #eff3f7;

      &:nth-child(even) {
        background-color: unset;
      }
    }

    .p-table-thead > tr > th {
      background-color: #eff3f7;
    }
  }

  .p-datatable-even {
    background-color: inherit;
  }

  .password-show {
    position: relative;

    .eye-icon {
      cursor: pointer;
      font-size: 18px;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 1;

      &.sign-in-eye {
        right: 10px;
        top: 13px;
      }
    }
  }

  .left-align {
    text-align: left;
  }
  @media screen and (max-width: 1440px) {
    .multiple-checked {
      margin-top: none;
    }
  }

  .header-weight {
    font-weight: 500;
  }

  .change-vehicle-label {
    padding-top: 1.5rem;
  }

  .doller-sign-bold {
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }
  .whitePatch {
    background-color: white;
    padding: 18px;
  }

  .web-marg-l {
    margin-left: 16.666%;
  }

  .confirm-popup-width {
    width: 500px;
  }

  @media screen and (max-width: 600px) {
    .add-routes-popup {
      .p-dialog-content {
        width: 90%;
      }

      .p-dialog-titlebar {
        width: 90%;
      }
    }

    .confirm-popup-width {
      margin-left: 15px;
    }
  }
}

.search-driver-map {
  input[type=text]{
    height: 33px !important;
  }
}

// .p-calendar-w-btn .p-datepicker-trigger {
//   height: 33px !important;
// }

.car-icon-0 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -847px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-1 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -60px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-2 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -250px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-3 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -445px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-4 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -648px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-5 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -852px -57px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-6 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -60px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-7 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -250px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-8 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -445px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-9 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -648px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-10 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -852px -148px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-11 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -60px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-12 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -250px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-13 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -445px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-14 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -648px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-15 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -852px -233px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-16 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -60px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-17 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -250px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-18 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -445px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-19 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -648px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-20 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -852px -327px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-21 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -60px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-22 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -250px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-23 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -445px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.car-icon-24 {
  background-image: url("/assets/images/temp/car_type_icons-2.png");
  background-position: -648px -418px;
  background-repeat: no-repeat;
  height: 35px;
  width: 180px;
  zoom: 1;
}

.p-datepicker {
  .p-datepicker-header {
    padding: 0 0.5rem;
  }

  table {
    td {
      padding: 2px;

      >span {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 3px;
        transition: background-color .2s, box-shadow .2s;
        border: 1px solid transparent;
      }
    }
  }
}

.warning-text {
  background-color: #fed1d1;
  padding: 5px 20px;
  font-size: 15px;
  color: red;
  position: relative;

  .close {
    font-size: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

p-confirmdialog {
  .p-dialog {
    .p-dialog-footer {
      button {
        float: inline-end;
      }
    }
  }
}
