.trip-details {
  .pickup-circle {
    color: $mapPickup;
  }

  .drop-circle {
    color: $mapDrop;
  }

  .one-line-block {
    float: right;
    margin-left: 20px;
  }

  .p-fileupload-buttonbar {
    padding: 0 0 0 10px;
  }
}

