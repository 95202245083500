.access-div {
  color: $accessDivTextColor;

  &.even {
    background: $accessDivEvenBgColor;
  }

  &.odd {
    background: $accessDivOddBgColor;
  }
}

.group-header {
  background: $groupHeaderBgColor;
  color: $white;
  margin: 0 0 20px;

  .group-title {
    font-weight: bold;
    text-transform: capitalize;
  }

  i {
    cursor: pointer;
  }
}
