app-header {
  background-color: $topBarBgColor;
  box-shadow: 0 7px 7px -6px rgba(0, 0, 0, .13);
  color: $topBarTextColor;

  a {
    color: $topBarTextColor;

    &:link,
    &:active,
    &:focus {
      color: $topBarTextColor;
      outline: none;
      text-decoration: none;
    }

    &.hamburger-menu {
      color: $humburgerMenuColor;
      cursor: pointer;
      display: block;
      font-size: 24px;
      height: 61px;
      line-height: 61px;
      padding: 0 16px;

      &:hover {
        color: $taxipulseThemeColor;
      }
    }
  }
}

.app-header {
  height: 48px;
}

.top-bar-item {
  float: left;

  &.notifications-bell {
    background-color: $defaultThemeColor;
    border: 1px solid $white;
    border-radius: 50px;
    height: 40px;
    margin: 11px 7px;
    padding: 5px 7px;
    position: relative;

    &::after {
      color: $black;
      content: '';
      display: inline-block;
      height: 60px;
      left: 55px;
      position: absolute;
      top: -16px;
      width: 1px;
    }

    .fa {
      font-size: 17px;
    }

    &:hover {
      background-color: $buttonHoverColor;
      cursor: pointer;

      .fa {
        color: $white;
      }
    }

    .new-notification-count {
      display: inline-block;
      font-size: 17px;
      line-height: 1.1;
      margin-left: 4px;
    }
  }

  &:hover {
    float: left;
  }
}

.nav-right-menu {
  float: right;

  .nav-notifications-icon {
    color: $topBarBgColor;
    line-height: 31px;
    padding: 0 10px;
  }

  .profile-menu-button {
    border-left: 2px solid $headerSplitBarColor;
    line-height: 57px;
    margin-left: 20px;

    a {
      border: 4px solid $white;
      color: $defaultTextColor;
      cursor: pointer;
      display: block;
      font-size: 15px;
      padding: 3px 5px;

      .user-photo {
        border: 1px solid $sideBarHoverBgColor;
        border-radius: 50%;
        display: block;
        float: left;
        height: auto;
        margin: 13px 12px 4px 4px;
        width: 35px;
      }

      .fa {
        margin-left: 8px;
      }

      &:hover {
        border-bottom: 4px solid $defaultThemeColor;
      }
    }

    .p-menuitem-link {
      border: 0;
      padding: 0;

      .fa {
        font-size: 20px;
        margin: 0 9px;
      }

      &:hover {
        background: $defaultThemeColor;
        border: 0;
      }
    }
  }
}

.p-menu {
  &.p-menuitem {
    clear: both;
    margin: 5%;
    padding: 0;
    width: 100%;
  }
}

.notification-try {
  p-overlayPanel {
    height: 350px;
    width: 350px;
  }

  .p-overlaypanel {
    height: 350px;
    margin-top: 11px;
    top: 61px;
    width: 350px;
  }

  .p-overlaypanel-content {
    background: $white;
    height: 350px;
    overflow: auto;
    padding: 0;
    width: 350px;

    hr {
      margin: 0;
    }

    .notification-show-more-link {
      padding: 20px;

      a {
        cursor: pointer;

        &:hover {
          color: $defaultThemeColor;
        }
      }
    }
  }
}

