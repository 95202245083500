.fa-2x {
  font-size: 2em;
  margin: 8px 0;
}

.div-scroll {
  height: 186px;
  overflow-y: auto;
  width: 100%;
}
