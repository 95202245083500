.company-logo {
  &.sign-in-page {
    background-color: $taxiPulseSecondaryColor;
    text-align: center;
  }

  img {
    max-width: 227px;
  }
}
.p-panelmenu .p-panelmenu-content {
  border: none;
}
.sidebar {
  background-color: $taxiPulseSecondaryColor;
  // height: 53rem;
  min-height: 100%;

  .company-logo {
    background-color: none;
    text-align: center;
  }

  .p-menuitem-text {
    font-size: 14px;
  }

  .p-widget-content {
    background-color: $taxiPulseSecondaryColor;
    border: 0 none;

    .p-menuitem-link {
      border-left: 3px solid $taxiPulseSecondaryColor;
      color: $sidebarMenuTextColor;
      height: 40px;
      padding: 8px 4px 3px;

      &:hover {
        background-color: $themeSecondaryHoverColor;
        border-left: 3px solid $taxipulseThemeColor;
        color: $sidebarMenuHoverTextColor;
      }

      &:active {
        background-color: $themeSecondaryHoverColor;
        // border-color: $taxipulseThemeColor;
        border-left: 0 solid $taxipulseThemeColor;
        color: $sidebarMenuHoverTextColor;
        padding: 8px 4px 3px;
      }

      .p-menuitem-text {
        font-size: 12px;
      }
    }

    .p-menuitem {
      &:not(.p-menuitem-link-active) {
        &:hover {
          background-color: $themeSecondaryHoverColor;
          border-color: $themeSecondaryHoverColor;
          color: $sidebarMenuHoverTextColor;
        }

        &:active {
          background-color: $themeSecondaryHoverColor;
          border-color: $themeSecondaryHoverColor;
          border-left: 3px solid $taxipulseThemeColor;
          color: $sidebarMenuHoverTextColor;
        }
      }

      .p-menuitem-link-active {
        background: $themeSecondaryHoverColor;
        border-color: $themeSecondaryHoverColor;
        border-left: 3px solid $taxipulseThemeColor;

        .p-menuitem-text {
          color: $taxipulseThemeColor;
          font-size: 12px;
          font-weight: 600;
        }

        .p-menuitem-icon {
          &.fa {
            color: $taxiPulseSideBarTextIconActiveColor;
          }
        }
      }
    }
  }

  .p-panelmenu {
    overflow-y: auto;
    max-height: calc(100vh - 75px);
    position: fixed;
    width: 20%;
    .fa {
      color: $sidebarIconColor;
      font-size: 20px;
      margin-right: 12px;
    }

    .p-panelmenu-header {
      a {
        background: transparent;
        border: 0;
        color: $sidebarMenuTextColor;
        display: inline-flex;
        font-weight: 400;
        height: 40px;
        padding: 20px 16px 10px;
        width: 100%;

        .pi-fw {
          display: none;
        }

        &.p-menuitem-link-active {
          background: #000;
        }
      }

      &:hover {
        background-color: $themeSecondaryHoverColor;
        border-color: $themeSecondaryHoverColor;
      }

      &:active {
        background-color: $themeSecondaryHoverColor;
        border-color: $themeSecondaryHoverColor;

        a {
          color: $white;
        }
      }

      .p-panelmenu-header-link {
        .p-menuitem-icon {
          background-image: url('/assets/images/temp/sprite_v222.png');
          background-repeat: no-repeat;
          color: #FFF;
          height: 20px;
          width: 25px;

          &.fa-dashboard-menu {
            //dashboard
            background-position: -31px -32px;
          }

          &.fa-booking-menu {
            // Bookings
            background-position: -31px -128px;
          }

          &.fa-passenger-menu {
            // passengers
            background-position: -31px -273px;
          }

          &.fa-driver-menu {
            // Drivers
            background-position: -31px -320px;
          }

          &.fa-vehicle-menu {
            // Vehicles
            background-position: -31px -225px;
          }

          &.fa-cities-menu {
            // Cities
            background-position: -31px -416px;
          }

          &.fa-app-setting-menu {
            // App Settings
            background-position: -31px -513px;
          }

          &.fa-user-management-menu {
            // User Mangement
            background-position: -31px -752px;
          }

          &.fa-operator-management-menu {
            // operator Mangement
            background-position: -31px -752px;
          }

          &.fa-marketing-menu {
            // Marketings
            background-position: -82px -896px;
          }

          &.fa-setting-menu {
            // Settings
            background-position: -81px -32px;
          }

          &.fa-report-menu {
            // Reports
            background-position: -81px -416px;
          }

          &.fa-company-menu {
            // Companies
            background-position: -31px -464px;
          }
        }

        &.p-menuitem-link-active {
          background: $themeSecondaryHoverColor;
          border-color: $themeSecondaryHoverColor;
          box-shadow: inset 3px 0 0 0 $taxipulseThemeColor;
          color: $taxiPulseSideBarTextIconActiveColor;
          font-weight: 600;

          .p-menuitem-icon {
            background-image: url('/assets/images/temp/sprite_v2.png');
            background-repeat: no-repeat;
            height: 20px;
            width: 25px;

            &.fa-dashboard-menu {
              //dashboard
              background-position: -141px -32px;
            }

            &.fa-booking-menu {
              // Bookings
              background-position: -141px -128px;
            }

            &.fa-passenger-menu {
              // passengers
              background-position: -141px -273px;
            }

            &.fa-driver-menu {
              // Drivers
              background-position: -141px -320px;
            }

            &.fa-vehicle-menu {
              // Vehicles
              background-position: -141px -225px;
            }

            &.fa-cities-menu {
              // Cities
              background-position: -141px -416px;
            }

            &.fa-app-setting-menu {
              // App Settings
              background-position: -141px -513px;
            }

            &.fa-user-management-menu {
              // User Mangement
              background-position: -141px -752px;
            }

            &.fa-operator-management-menu {
              // operator Mangement
              background-position: -31px -752px;
            }

            &.fa-marketing-menu {
              // Marketings
              background-position: -191px -896px;
            }

            &.fa-setting-menu {
              // Settings
              background-position: -191px -32px;
            }

            &.fa-report-menu {
              // Reports
              background-position: -191px -416px;
            }

            &.fa-company-menu {
              // Companies
              background-position: -142px -464px;
            }
          }
        }

        &:hover {
          background-color: $themeSecondaryHoverColor;
          border: 0;
          box-shadow: inset 3px 0 0 0 $taxipulseThemeColor;
          color: #131212;
        }
      }
    }

    // .p-panelmenu-content-wrapper {
      .p-panelmenu-content {
        border: 0;
        padding: 0;
        width: 90%;
        .p-menuitem {
          border-radius: 0;
  
          .p-menuitem-link {
            background-color: $themeSecondaryHoverColor;
            color: $black;
            padding-left: 5px;
            margin-left: 15px;
  
            &:focus {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem transparent;
            }
  
            &:hover {
              background-color: $themeSecondaryHoverColor;
  
              .p-menuitem-text {
                color: $black;
              }
            }
          }
        }
      }
  
      .p-menuitem-icon {
        background-image: url('/assets/images/temp/sprite_v222.png');
        // background-image: url('/assets/images/temp/sprite_AEL.png');
        background-repeat: no-repeat;
        height: 20px;
        width: 25px;
  
        &.fa-about-company-menu {
          // About Company
          background-position: -32px -560px;
        }
  
        &.fa-faq-menu {
          // FAQ
          background-position: -32px -608px;
        }
  
        &.fa-term-condition-menu {
          // Term & conditions
          background-position: -33px -705px;
        }
  
        &.fa-privacy-policy-menu {
          // Privacy Policy
          background-position: -33px -657px;
        }
  
        &.fa-manage-user-menu {
          // Manage Users
          background-position: -33px -803px;
        }
  
        &.fa-manage-operator-menu {
          background-position: -33px -803px;
        }
  
        &.fa-manage-role-menu {
          // Manage Roles
          background-position: -33px -848px;
        }
  
        &.fa-promocode-menu {
          // Promocode
          background-position: -81px -177px;
        }
  
        &.fa-notification-menu {
          // Notifications
          background-position: -32px -895px;
        }
  
        &.fa-segment-menu {
          // Segments
          background-position: -33px -705px;
        }
  
        &.fa-time-setting-menu {
          // Time settings
          background-position: -81px -81px;
        }
  
        &.fa-vehicle-type-menu {
          // Vehicle Type
          background-position: -81px -128px;
        }
  
        &.fa-reference-setting-menu {
          // Reference Setting
          background-position: -82px -224px;
        }
  
        &.fa-redius-menu {
          // Redius Settings
          background-position: -81px -272px;
        }
  
        &.fa-tax-menu {
          // Manage Taxes
          background-position: -82px -320px;
        }
  
        &.fa-ride-share-menu {
          // Ride Share Settings
          background-position: -81px -366px;
        }
  
        &.fa-driver-login-logout-report-menu {
          // Driver login logout
          background-position: -82px -465px;
        }
  
        &.fa-driver-on-duty-report-menu {
          // Driver on duty
          background-position: -82px -512px;
        }
  
        &.fa-driver-mileage-menu {
          // Driver mileage
          background-position: -82px -560px;
        }
  
        &.fa-driver-performace-menu {
          // Driver performance
          background-position: -80px -608px;
        }
  
        &.fa-car-mileage-menu {
          //car Mileage
          background-position: -82px -658px;
        }
  
        &.fa-refferal-credit-menu {
          // Refferal Credits
          background-position: -82px -702px;
        }
  
        &.fa-passenger-feedback-menu {
          // Passenger feedback
          background-position: -83px -756px;
        }
  
        &.fa-driver-income-menu {
          // Driver Income
          background-position: -82px -930px;
        }
  
        &.fa-company-income-menu {
          // Company Income
          background-position: -83px -848px;
        }
  
        // ael submenu inactive start
        &.fa-current-booking {
          // 
          background-position: -113px -847px;
        }
  
        &.fa-teams {
          background-image: url('/assets/images/temp/team.svg');
        }
  
        &.fa-driver-schedule {
          background-image: url('/assets/images/temp/schedule.svg');
        }
  
        &.fa-booking-history {
          // submenu
          background-position: -162px -847px;
        }
  
        &.fa-manage-driver {
          // submenu
          background-position: -211px -848px;
        }
  
        &.fa-driver-dashboard {
          // submenu
          background-position: -260px -848px;
        }
  
        &.fa-manage-task {
          // submenu
          background-position: -260px -848px;
        }
  
        &.fa-manage-users {
          // submenu
          background-position: -309px -848px;
        }
  
        &.fa-manage-roles {
          // submenu
          background-position: -358px -848px;
        }
  
        &.fa-manage-notification {
          // submenu
          background-position: -407px -848px;
        }
  
        &.fa-manage-segment {
          // submenu
          background-position: -456px -848px;
        }
  
        &.fa-about-company {
          // submenu
          background-position: -504px -848px;
        }
  
        &.fa-faq {
          // submenu
          background-position: -553px -848px;
        }
  
        &.fa-term-condition {
          // submenu
          background-position: -602px -848px;
        }
  
        &.fa-privacy-policy {
          // submenu
          background-position: -651px -848px;
        }
  
        &.fa-driver-login-logout-report {
          // submenu
          // background-position: -703px -850px; // old unique
          background-position: -949px -847px;
        }
  
        &.fa-driver-checkin-report {
          // submenu
          // background-position: -113px -847px; // old unique
          background-position: -949px -847px;
        }
  
        &.fa-shift-status-report {
          // submenu
          // background-position: -307px -326px; // old unique
          background-position: -949px -847px;
        }
  
        &.fa-driver-duty-status-report {
          // submenu
          // background-position: -752px -849px; // old unique
          background-position: -949px -847px;
        }
  
        &.fa-driver-on-duty-report {
          // submenu
          background-position: -752px -849px;
        }
  
        &.fa-driver-performace {
          // submenu
          // background-position: -801px -852px; // old unique
          background-position: -949px -847px;
        }
  
        &.fa-car-mileage {
          // submenu
          // background-position: -850px -850px; // old unique
          background-position: -949px -847px;
        }
  
        &.fa-passenger-feedback {
          // submenu
          // background-position: -899px -848px; // old unique
          background-position: -949px -847px;
        }
  
        &.fa-passenger-bookings {
          // submenu
          background-position: -949px -847px;
        }
  
        &.fa-time-setting {
          // submenu
          background-position: -834px -327px;
        }
  
        &.fa-load-booking {
          // submenu
          background-position: -641px -326px;
        }
  
        &.fa-content-menu {
          // submenu
          background-position: -641px -326px;
        }
  
        &.fa-priority-setting {
          // submenu
          background-position: -834px -327px;
        }
  
        &.fa-privilege-setting {
          // submenu
          background-position: -880px -327px;
        }
  
        &.fa-notification-setting {
          // submenu
          background-position: -880px -327px;
        }
  
        &.fa-pcn-list-view {
          // submenu
          // background-position: -703px -850px; // old unique
          background-position: -949px -847px;
        }
        
        &.fa-pcn-map-view {
          // submenu
          // background-position: -703px -850px; // old unique
          background-position: -949px -847px;
        }
  
        // ael submenu inactive end
      }
  
      .p-menuitem-link {
        color: $white;
        display: inline-flex;
        padding-left: 25px;
        width: 100%;
  
        &.p-menuitem-link-active {
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem transparent;
          }
  
          color: $black;
          font-weight: 500;
  
          .p-menuitem-icon {
            background-image: url('/assets/images/temp/sprite_v2.png');
            // background-image: url('/assets/images/temp/sprite_AEL.png');
            background-repeat: no-repeat;
            height: 20px;
            width: 25px;
  
            &.fa-about-company-menu {
              // About Company
              background-position: -142px -560px;
            }
  
            &.fa-faq-menu {
              // FAQ
              background-position: -142px -608px;
            }
  
            // &.fa-content-menu {
            //   // Content
            //   background-position: -142px -608px;
            // }
  
            &.fa-term-condition-menu {
              // Term & conditions
              background-position: -143px -705px;
            }
  
            &.fa-privacy-policy-menu {
              // Privacy Policy
              background-position: -143px -657px;
            }
  
            &.fa-manage-user-menu {
              // Manage Users
              background-position: -143px -803px;
            }
  
            &.fa-manage-operator-menu {
              background-position: -143px -803px;
            }
  
            &.fa-manage-role-menu {
              // Manage Roles
              background-position: -143px -848px;
            }
  
            &.fa-promocode-menu {
              // Promocode
              background-position: -191px -177px;
            }
  
            &.fa-notification-menu {
              // Notifications
              background-position: -142px -895px;
            }
  
            &.fa-segment-menu {
              // Segments
              background-position: -143px -705px;
            }
  
            &.fa-time-setting-menu {
              // Time settings
              background-position: -191px -81px;
            }
  
            &.fa-vehicle-type-menu {
              // Vehicle Type
              background-position: -191px -128px;
            }
  
            &.fa-reference-setting-menu {
              // Reference Setting
              background-position: -192px -224px;
            }
  
            &.fa-redius-menu {
              // Redius Settings
              background-position: -191px -272px;
            }
  
            &.fa-tax-menu {
              // Manage Taxes
              background-position: -192px -320px;
            }
  
            &.fa-ride-share-menu {
              // Ride Share Settings
              background-position: -191px -366px;
            }
  
            &.fa-driver-login-logout-report-menu {
              // Driver login logout
              background-position: -192px -465px;
            }
  
            &.fa-driver-on-duty-report-menu {
              // Driver on duty
              background-position: -192px -512px;
            }
  
            &.fa-driver-mileage-menu {
              // Driver mileage
              background-position: -192px -560px;
            }
  
            &.fa-driver-performace-menu {
              // Driver performance
              background-position: -190px -608px;
            }
  
            &.fa-car-mileage-menu {
              //car Mileage
              background-position: -192px -658px;
            }
  
            &.fa-refferal-credit-menu {
              // Refferal Credits
              background-position: -192px -702px;
            }
  
            &.fa-passenger-feedback-menu {
              // Passenger feedback
              background-position: -193px -756px;
            }
  
            &.fa-driver-income-menu {
              // Driver Income
              background-position: -191px -930px;
            }
  
            &.fa-company-income-menu {
              // Company Income
              background-position: -193px -848px;
            }
  
            // ael submenu active start
            &.fa-current-booking {
              // 
              background-position: -113px -897px;
            }
  
            &.fa-teams {
              background-image: url('/assets/images/temp/team-active.svg');
            }
  
            &.fa-driver-schedule {
              background-image: url('/assets/images/temp/schedule-active.svg');
            }
  
            &.fa-booking-history {
              // submenu
              background-position: -162px -897px;
            }
  
            &.fa-manage-driver {
              // submenu
              background-position: -211px -898px;
            }
  
            &.fa-driver-dashboard {
              // submenu
              background-position: -260px -898px;
            }
  
            &.fa-manage-task {
              // submenu
              background-position: -260px -898px;
            }
  
            &.fa-manage-users {
              // submenu
              background-position: -309px -897px;
            }
  
            &.fa-manage-roles {
              // submenu
              background-position: -358px -898px;
            }
  
            &.fa-manage-notification {
              // submenu
              background-position: -407px -897px;
            }
  
            &.fa-manage-segment {
              // submenu
              background-position: -456px -898px;
            }
  
            &.fa-about-company {
              // submenu
              background-position: -504px -897px;
            }
  
            &.fa-faq {
              // submenu
              background-position: -553px -897px;
            }
  
            &.fa-term-condition {
              // submenu
              background-position: -602px -897px;
            }
  
            &.fa-privacy-policy {
              // submenu
              background-position: -651px -897px;
            }
  
            &.fa-driver-login-logout-report {
              // submenu
              // background-position: -703px -898px; // old unique
              background-position: -949px -896px;
            } 
  
            &.fa-driver-checkin-report {
              // submenu
              // background-position: -113px -897px; // old unique
              background-position: -949px -896px;
            }
  
            &.fa-shift-status-report {
              // submenu
              // background-position: -307px -376px; // old unique
              background-position: -949px -896px;
  
            }
  
            &.fa-driver-duty-status-report {
              // submenu
              // background-position: -752px -898px; // old unique
              background-position: -949px -896px;
            }
  
            &.fa-driver-on-duty-report {
              // submenu
              background-position: -752px -898px;
            }
  
            &.fa-driver-performace {
              // submenu
              // background-position: -801px -899px; // old unique
              background-position: -949px -896px;
            }
  
            &.fa-car-mileage {
              // submenu
              // background-position: -850px -898px;  // old unique
              background-position: -949px -896px;
            }
  
            &.fa-passenger-feedback {
              // submenu
              // background-position: -899px -897px; // old unique
              background-position: -949px -896px;
            }
  
            &.fa-passenger-bookings {
              // submenu
              background-position: -949px -896px;
            }
  
            &.fa-time-setting {
              // submenu
              background-position: -834px -378px;
            }
  
            &.fa-load-booking {
              // submenu
              background-position: -641px -326px;
            }
  
            &.fa-priority-setting {
              // submenu
              background-position: -834px -378px;
            }
  
            &.fa-privilege-setting {
              // submenu
              background-position: -880px -378px;
            }
  
            &.fa-notification-setting {
              // submenu
              background-position: -880px -378px;
            }
  
            &.fa-pcn-list-view {
              // submenu
              // background-position: -703px -850px; // old unique
              background-position: -949px -896px;
            }
  
            &.fa-pcn-map-view {
              // submenu
              // background-position: -703px -850px; // old unique
              background-position: -949px -896px;
            }
            // ael submenu active end
          }
        }
      }
    // }
  }

  a {
    color: $sidebarMenuHoverTextColor;
  }

  .p-state-default {
    background: $taxiPulseSecondaryColor;
    border: 1px solid $taxiPulseSecondaryColor;
    color: $sidebarMenuTextColor;
  }

  .fa-caret-right,
  .fa-caret-down {
    &::before {
      content: '';
    }
  }

  .p-panelmenu-icon {
    // &.fa {
      display: none;
      font-size: 18px;
    // }
  }
}

.header-panel {
  position: absolute;

  .page-header-menu {
    background: $white;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1000;

    .company-logo {
      background-color: #3B7BFF;
      padding-top: 15px;
      text-align: center;
    }

    p-menu {
      .p-menu {
        &.p-menu-dynamic {
          top: 63px !important; // sass-lint:disable-line no-important
        }
      }
    }
  }
}

.main-panel {
  background: #EFF2F7;

  .right-panel {
    padding: 13px;
  }
}

@media screen and (min-width: 1600px) {
  .left-panel {
    width: 15%;
  }

  .right-panel {
    width: 85%;
  }

  .closed {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .left-panel {
    width: 20%;
  }

  .right-panel {
    width: 80%;
  }

  .closed {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .left-panel {
    width: 22%;
  }

  .right-panel {
    width: 78%;
  }

  .closed {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  .left-panel {
    width: 30%;
  }

  .right-panel {
    width: 70%;
  }

  .closed {
    .right-panel {
      width: calc(100% - 60px);
    }
  }
}

@media screen and (max-width: 767px) {
  .header-panel {
    display: block;

    .page-header-menu {
      display: block;

      .app-header {
        display: none;
      }

      p-menu {
        .p-menu {
          &.p-menu-dynamic {
            top: 125px !important; // sass-lint:disable-line no-important
          }
        }
      }
    }
  }

  .main-panel {
    display: block;
    margin-top: 125px;

    .right-panel {
      float: left;
    }

    .sidebar {
      min-height: auto;
      width: 100%;
    }
  }

  .nav-right-menu {
    float: auto;
    width: 100%;

    .profile-menu-button {
      float: right;
    }
  }

  .profile-panel {
    width: 100%;
  }
}
