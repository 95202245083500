.signin-box-container {
  background: url('/assets/images/temp/bg.png') no-repeat center center fixed;
  background-size:100% 100%;
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;

  .signin-box-inner-container {
    display: table-cell;
    vertical-align: middle;
    overflow-y: hidden;
    overflow-x: hidden;

    .p-panel {
      .p-panel-titlebar {
        padding: 0;
      }
    }

    .switch-btn {
      color: $defaultTextColor;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }

    .signin-box {
      margin: 0 auto;
      position: relative;
    }

    .logo-box {
      margin: 0 auto;
      position: relative;
    }

    .bottom-border {
      border-bottom: 1px solid $signInBorderColor;
    }

    .forgot-password-link {
      color: $defaultTextColor;
      cursor: pointer;
      font-size: 12px;
    }

    .p-state-default {
      border: 0;
    }

    .sign-in-buttons {
      border-radius: 18px;
      width: 110%;
      white-space: nowrap;
      justify-content: center;
      margin: 12px auto;
    }

    input {
      &:hover {
        border: 0;
      }

      &:focus {
        border: 0;
      }
    }

    .read-more-button {
      background: transparent;
      border: 1px solid $white;
      border-radius: 18px;
      width: 75%;
    }

    .selected-menu {
      background-color: $defaultThemeColor;
      height: 4px;
      padding: 0;
    }

    .float-left {
      float: left;
    }

    .float-right {
      float: right;
    }

    h2 {
      color: $white;
      font-weight: normal;
      line-height: 2;
    }

    h4 {
      color: $white;
      font-weight: normal;
      line-height: 2;
    }

    .term-condition {
      margin: 15px 0;
    }
  }

  // .login-img {
  //   background: url('/assets/images/temp/loginImg.svg') no-repeat center center fixed;
  //   background-size:cover;
  //   display: table;
  //   height: 100%;
  //   left: 0;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  //   z-index: 1000;
  // }
}

// .p-panel .p-panel-header {
//   border: 1px solid #c8c8c8;
//   /* padding: 0.857rem 1rem; */
//   background: #f4f4f4;
//   color: #333333;
//   border-top-right-radius: 3px;
//   border-top-left-radius: 3px;
// }
.p-toggleable-content {
  // padding: 0.571em 1em;
  // border: 1px solid #c8c8c8;
  background-color: #fff;
  color: #333;
  border-top: 0;
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: #a80000;
  // width: 170%;
}


.signin-box-container .signin-box-inner-container .forgot-password-link {
    color: #101010;
    cursor: pointer;
    font-size: 12px;
}

.p-panel-header{
  background-color: #fff !important;
  border: none !important;
  padding: 0 !important;
}

.go-to-login {
  color: blue;
  cursor: pointer;
}

.sso-btn {
  padding: 7px 40px;
  font-weight: 500;
  border-radius: 20px;
}
