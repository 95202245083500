.selected {
  background-color: rgba(0, 0, 0, .18);
  height: 100%;
  width: 100%;
  z-index: 2;
}

.user-profile-pic {
  border-radius: 100%;
  max-width: 100%;
}

.vertical-ceter-allign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.selected-img {
  background-color: rgba(0, 0, 0, .18);
  height: 100%;
  width: 100%;
  z-index: 2;
}

.promo-success {
  color: $taxipulseThemeColor;
  font-size: 18px;
  font-weight: bold;
}

.promo-amount-msg {
  color: #C2C2C2;
}

.clear-promo {
  cursor: pointer;
  margin: 0 15px;
}

button {
  &.p-button {
    &.p-state-default {
      &.promo-code-button {
        background: transparent;
        border: 2px solid $taxipulseThemeColor;
        color: $taxipulseThemeColor;
      }
    }
  }
}
