.time-setting-spinner {
  .p-spinner {
    width: 65px;

    input {
      padding-left: 5px;
    }
  }
}

.time-setting-group-name {
  color: $timeSettingTextColor;
  font-size: 15px;
  font-weight: bold;
}

.time-settings {
  margin: 10px;
  padding: 0;

  .p-panel-titlebar {
    background: $sideBarHoverBgColor;
    border: 0;
    border-radius: 0;
    color: $white;
    font-size: 15px;
    padding: 8px 16px;

    .p-panel-titlebar-icon {
      &:hover {
        background: $secondaryThemeColor;
        border: 1px solid $secondaryThemeColor;
      }
    }
  }

  .p-panel-content {
    overflow: hidden;

    .time-setting-block {
      background: $bodyBgColor;
      border: 1px solid $contentBorderColor;
      padding: 10px;
    }
  }
}

.time-settings-unique-block {
  padding: 0 15px;

  .time-setting-block {
    .form-field {
      label {
        opacity: .8;
        right: 10px;
        position: relative;
      }
    }

    .time-setting-button {
      button {
        margin-top: 35px;
      }
    }

    .time-block-section {
      border-bottom: 1px solid $timeBlockSectionBorderColor;

      .time-setting-description {
        padding-top: 30px;
      }
    }
  }
}

.ui-spinner-input {
  right: 15px;
  position: relative;
}

.ui-spinner .ui-spinner-button.ui-spinner-up {
  border-top-right-radius: 3px;
}

.ui-spinner .ui-spinner-button.ui-spinner-down {
  border-bottom-right-radius: 3px;
}

.ui-spinner .ui-spinner-button {
  color: rgb(255, 255, 255);
  font-weight: 500;
  background: rgb(59, 123, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(59, 123, 255);
  border-image: initial;
}
