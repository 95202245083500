.staff-transport-info-company {
  padding: 0 20px 20px;

  .info-label {
    display: inline-block;
    font-weight: bold;
  }

  .info-data {
    display: inline-block;
  }

  .img-width {
    max-width: 95px;
    width: 100%;
  }
}
