
.assign-vehicle-view {
  border: 1px solid $black;

  &.form-content {
    margin: 30px;
    padding: 30px;
  }

  .col-12 {
    padding: 0;
  }

  .edit-vehicle-link {
    cursor: pointer;
  }
}

.p-chips-input-token {
  .p-inputtext {
    margin: 0;
    visibility: hidden;
  }
}


.align-right {
  text-align: right;
}
