// Colors 
$white: #FFF;
$defaultThemeColor: #3B7BFF;
$defaultTextColor: #101010;
$secondaryThemeColor: #454545;
//$secondaryThemeColor: rgb(71, 170, 250);
$inputGroupTextColor: #000;
$bodySectionBgColor: #EFF2F7;

// $bodyBgColor: #F6F6F6;
$bodyBgColor: #EFF2F7;

$mainContentBgColor: #F6F6F6;
$topBarBgColor: $white;
$topBarItemHoverBgColor: #F1F1F1;
$BodyTextColor: #454545;
$allTextColor: #333;

$linkColor: #666161;
$topBarTextColor: #303030;

$sidebarBgColor: $secondaryThemeColor;
$sidebarMenuTextColor: #000;
$sideBarHoverBgColor: #51575C;
$sidebarMenuHoverTextColor: #131212;
$sidebarIconColor: #B0BEC5;

$signInBgColor: #009688;
$signInBorderColor: #B8B8B8;
$headerFontSize: 24px;
$headerSplitBarColor: #CBD1DC;
$headerUserName: #101010;

$buttonDefaultTextColor: $white;
$buttonDefaultBgColor: $defaultThemeColor;
$negativeButtonColor: $secondaryThemeColor;
$buttonHoverColor: #3B7BFF;
$negativeButtonHoverColor: #24282A;

$pageHeaderBgColor: transparent;
$panelDivBgColor: $white;

// Forms

$formFieldTopBottomMargin: 8px;
$formFieldLeftRightMargin: 0;
$inputFieldsBgColor: $white;
$inputFieldBorderColor: #E1E3E7;
$inputTextHeight: 30px;
$readOnlyFieldColor: #DEDEDE;
$activeBoxTextColor: #636363;
$inputBorderColor: #E1E3E7;

// Pring NG 

$fontFamily: 'OpenSans', sans-serif;
$fontSize: 15px;
$borderRadius: 2px;
$disabledOpacity: .35;
$calendarIconColor: #3D3D3D;
$dropdownBgColor: #1A1A1A;
$dropdownBorderColor: #C5C0CB;
$selectButtonBorderColor: #EEE;

// Header
$headerBorderWidth: 1px;
$headerBorderColor: $white;
$headerBgColor: $white;
$headerTextColor: #444;
$headerFontWeight: bold;
$headerIconTextColor: #444;
$dialogTitlebarTextColor: #464444;

// Content
$contentBorderWidth: 1px;
$contentBorderColor: #C8C8C8;
$contentBgColor: $white;
$contentTextColor: #444;

// Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #DDD;
$stateDefaultBgColor: #F6F6F6;
$stateDefaultTextColor: #0073EA;

// Active State
$stateActiveBorderColor: #DDD;
$stateActiveBgColor: $white;
$stateActiveTextColor: $white;

// Highlight State
$stateHighlightBorderColor: #9158D9;
$stateHighlightBgColor: #9158D9;
$stateHighlightTextColor: $white;

// Focus State
$stateFocusBorderColor: #0073EA;
$stateFocusBgColor: #0073EA;
$stateFocusTextColor: $white;

// Error State
$stateErrorBorderColor: #DDD;
$stateErrorBgColor: $white;
$stateErrorTextColor: #FF0084;

// Hover State
$stateHoverBorderColor: #0073EA;
$stateHoverBgColor: #0073EA;
$stateHoverTextColor: $white;

// Forms
$inputBgColor: $white;
$inputTextColor: #444;
$invalidInputBorderColor: #FF0084;

// Glow messages
$growlMessageErrorColor: #F44336;
$growlMessageSuccessColor: #4CAF50;

// Grid 
$gridHeaderTextColor: #1E1E1E;
$gridHeaderBgColor: #EDEDED;
$gridHeaderBorderColor: $topBarBgColor;
$gridBtnColorDanger: #D9534F;
$gridBtnColorDangerFocus: #C9302C;
$gridBtnColorSuccess: #5CB85C;
$gridBtnColorSuccessFocus: #449D44;
$loaderBgColor: #DDD;
$actionButtonDeactivate: #1E1E1E;
$gridSelectedRowHover: #D9DBDD;
$gridFileHoverColor: #4CAF50;

$gridEvenRowBgColor: #F4F5FA;
$gridEvenRowStateHighlightBgColor: #F9F9F9;
$gridOddRowStateHighlightBgColor: $gridEvenRowBgColor;
$gridBottomBorderColor: #C0BEBE;

$color_log_cabin_approx: #222;
$color_boulder_40_approx: rgba(120, 120, 120, .4);
$color_fuscous_gray_40_approx: rgba(80, 80, 80, .4);
$color_shark_40_approx: rgba(40, 40, 40, .4);
$black_40: rgba(0, 0, 0, .4);
$color_japanese_laurel_approx: #006400;
$color_red_berry_approx: #8B0000;
$color_surf_crest_approx: #CEC;
$color_quill_gray_approx: #D3D3D3;
$color_pumice_approx: #C7C7C7;
$color_danube_approx: #5B9BD5;
$color_silver_approx: #BEBEBE;
$color_black_haze_approx: #F6F6F6;
$color_gallery_approx: #F0F0F0;
$white_50: rgba(255, 255, 255, .5);
$black: #000;
$gray: #808080;

$gridOddRowColor: $white;
$gridRowSelectedColor: #EEEAEA;
$gridSelectorHighliterColor: #EDEDED;
$paginationTextColor: #3E3E3E;


// Checkbox
$checkboxBackgroundColor: $white;
$checkboxBorderColor: #EDEDED;
$checkboxActiveBorderColor: #E1E3E7;
$checkboxCheckColor: $defaultThemeColor;

// Table
$tableHeaderText: rgba(30, 30, 30, .5);

// google map realate color
$mapPickup: #4DA03A;
$mapDrop: #EC1D25;
$routeDrawpointDivBgColor: #6B5B5F;
$mapPickupBorderColor: $mapPickup;

$destinationBox: #E9E9EC;

// taxi pulse
$taxipulseThemeColor: #3B7BFF;
$themeButtonHoverColor: #3B7BFF;
$taxiPulseSecondaryColor: #FFF;
$themeSecondaryHoverColor: #FFF;
$carTypeIconHighlight: #868686;
$carTypeDropdownHover: #B7B7B7;

//taxi pulse side bar
$taxiPulseSideBarTextIconActiveColor: $taxipulseThemeColor;
$evenRowColor: #F4F5FA;
$gridTextColor: #333;


$accessDivTextColor: #1E1E1E;
$accessDivEvenBgColor: $white;
$accessDivOddBgColor: #F1F1F1;
$groupHeaderBgColor: #3D4246;
$groupHeaderTextColor: $groupHeaderBgColor;

$timeSettingTextColor: #8491A6;
$timeBlockSectionBorderColor: #C6C6C6;
$humburgerMenuColor: #B0BEC5;
$newRequestColor: #F00;
$attendRequestColor: #808080;
$closeRequestColor: #080;
$assignVehicleTextColor: #444;
$removeVehicleParentBgColor: #EFEFEF;

$listHoverBgColor: #AAA;
$listHighLightBgColor: $listHoverBgColor;
$listBorderColor: $listHoverBgColor;

$pDatatableBgColor: #E4E4E4;
$pDatatableBorderColor: #CCC;
$tabViewHoverColor: $pDatatableBorderColor;
$separatorBgColor: $pDatatableBorderColor;
$dividerBgColor: $pDatatableBorderColor;

$vehicleTextColor: #3B7BFD;
$dialogCloseColor: $vehicleTextColor;
$notifyTextColor: #07CD79;
$successStatus: #008000 ;
$startPoint: #00B911;
$endtPoint: #F00;
$BookingDetailBorderColor: #00B911;
$maxLimitColor: #5D5C5C;
$dropBoxBorderColor: #DED7D7;

// Booking Detail
$bookingTabledataColor: #9B9B9B;

// Stribe Card

$checkoutTextColor: #525252;
$checkoutButtonBgColor: #FF7F50;
$checkoutButtonActiveBgColor: rgb(165, 76, 43);

$surgeBlockBorderColor: #DEDEDE;
$fileUploadButtonbarBgColor: #F0F2F7;
$tabViewDefaultBgColor: #A0A0A0;

$biddingDetailContainerOddColor: #F2F2F2;
$trackMyLocationDataColor: #A2A2A2;
$activeStatus: #4DA03A;
$deactiveStatus: #F44336;

