.trip-details {
  p-dialog {
    .p-fileupload-buttonbar {
      padding: 0;
    }

    .negative-button {
      margin-left: 12px;
    }
  }
}
