.form-buttons-full-width {
  margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;

  button {
    display: block;
    width: 100%;
  }

  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

.dashboard-map-container {
  height: 650px;
  padding: 7px 0;
}

.admin-stats-counts {
  background-color: $white;
  border: solid 1px $black;
  height: auto;
  width: 100%;
}

.admin-dash-stats-count {
  text-align: center;
  width: 100%;
}

.admin-dash-stats-label {
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.activity-header-area {
  background-color: transparent;
  padding: 0 10px;
}

.activity-body-area {
  background-color: $white;
}

.activity-box {
  border: solid 1px $black;
}

.show-all-notifs {
  color: $notifyTextColor;
  cursor: pointer;
  float: right;
  padding: 7px 0;
  text-decoration: underline;
}

.booking-stats {
  background-color: $white;
  border: solid 1px $black;
  height: 271px;
  margin-top: 3px;
  width: 100%;

  &.bar-chart-wrapper {
    height: 100%;
  }
}

.booking-forms {
  border: solid 1px $black;
}

.pie-block {
  background-color: $white;
  border: solid 1px $black;
  height: auto;
  text-align: center;
}

.pie-header {
  text-align: center;
  width: 100%;
}

.dashboard-form {
  .p-dropdown {
    margin: 8px 0 0;
  }
}

.pie-container {
  background-color: $white;
  border: solid 1px $black;
  height: auto;
  text-align: center;
}

.disguise-read-only {
  background-color: $readOnlyFieldColor;
  border: 1px solid $inputBorderColor;
  font-size: 14px;
  margin: 8px 0;
  padding: 7px 5px;
}


