@import './app/styles/theme01';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/themes/nova/theme.css';
@import '../node_modules/primeflex/primeflex.css';
@import './app/styles/shared/components/mi-grid';
@import './app/styles/shared/components/mi-grid-2';
@import './app/styles/common';
@import './app/styles/forms';
@import './app/styles/dashboards';
@import './app//styles/shared/components/page-loader.component';

@import './app/styles/shared/components/document-info.component';
@import './app/styles/shared/components/header.component';
@import './app/styles/shared/components/sidebar.component';
@import './app/styles/shared/directives/search-location.directive';
@import './app/styles/shared/components/notification-mg1.component';

@import './app/styles/staff-transport/components/add-or-edit-staff-transport';
@import './app/styles/staff-transport/components/assigned-vehicle-list-view.component';
@import './app/styles/staff-transport/components/staff-transport-info-component';
@import './app/styles/staff-transport/components/active-package-info.component';


@import './app/styles/sales-quotation/component/trip-details.component';
@import './app/styles/sales-quotation/component/upload-quote.component';

@import './app/styles/manual-booking/manual-booking';
@import './app/styles/settings/setting';
@import './app/styles/settings/editable-routes';

@import './app/styles/app/settings/time-setting';
@import './app/styles/app/settings/access-management';


@import './app/styles/app/pages/sign-in.component';
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';




