app-active-package-info {
  .active-package-details {
    margin: 20px 0;

    .pickup-circle {
      color: $mapPickup;
    }

    .drop-circle {
      color: $mapDrop;
    }

  }

  .staff-transport-header {
    font-size: 18px;
    margin-left: 20px;
  }
}

.view-route-link {
  cursor: pointer;
}

.p-listbox {
  overflow: auto;
  padding: .25em;
  width: 100%;
}

.map-pick-point {
  border: 6px solid $mapPickupBorderColor;
  border-radius: 50px;
  height: 12px;
  margin: 2px;
  position: absolute;
  width: 12px;
}

.drop-address {
  padding-left: 20px;
}

.route-draw-points {
  margin: -9px 0 0 1px;

  div {
    background-color: $routeDrawpointDivBgColor;
    border-radius: 50%;
    height: 4px;
    margin: 1px 0 0 5px;
    top: 5px;
    width: 4px;
  }

  &:last-child {
    display: none;
  }
}

